import { useState, useMemo } from 'react';
import { useMenu } from './MenuContext';
import deepEqual from 'deep-equal';


const deleteFalseValues = (obj) => {
  if (!obj) {
    return {};
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] === false) {
      delete obj[key];
    }
  });
  return obj;
};
const ProductsController = ({
  activeCategoryId,
  children,
}) => {
  const {
    products,
    subCategories,
    productsBySubCategory,
  } = useMenu();
  const categoryProducts = useMemo(
    () => products.filter(({ category_id }) => activeCategoryId === category_id || !activeCategoryId),
    [activeCategoryId, products],
  );
  const [selectedProducts, setSelectedProducts] = useState({});
  const onToggleProduct = (productId) => {
    setSelectedProducts(deleteFalseValues({
      ...selectedProducts,
      [productId]: !selectedProducts[productId],
    }));
  };
  const allChecked = categoryProducts.length === Object.keys(selectedProducts).length;
  const someChecked = Object.keys(selectedProducts).length > 0;
  const onToggleAll = () => {
    if (allChecked) {
      setSelectedProducts({});
    } else {
      setSelectedProducts(
        categoryProducts.reduce(
          (acc, product) => ({
            ...acc,
            [product.id]: true,
          }),
          {},
        ),
      );
    }
  };
  const getSubCategoryCheckboxState = (subCategoryId) => {
    if (!activeCategoryId) {
      return false;
    }
    const subCategoryProducts = productsBySubCategory[activeCategoryId][subCategoryId];
    if (!subCategoryProducts) {
      return false;
    }
    const selectedSubCategoryProducts = subCategoryProducts.filter(
      (product) => selectedProducts[product.id],
    );
    if (selectedSubCategoryProducts.length === subCategoryProducts.length) {
      return 'CHECKED';
    }
    if (selectedSubCategoryProducts.length > 0) {
      return 'INDETERMINATE';
    }
    return 'UNCHECKED';
  };
  const onToggleSubCategory = (subCategoryId) => {
    const state = getSubCategoryCheckboxState(subCategoryId);
    const subCategoryProducts = productsBySubCategory[activeCategoryId][subCategoryId];
    setSelectedProducts(
      deleteFalseValues(
        subCategoryProducts.reduce(
          (acc, product) => ({
            ...acc,
            [product.id]: state === 'UNCHECKED' || state === 'INDETERMINATE',
          }),
          selectedProducts,
        ),
      ),
    );
  };
  const [closedSubCategories, setClosedSubCategories] = useState([]);
  const onToggleSubCategoryOpen = (subCategoryId) => {
    if (closedSubCategories.includes(subCategoryId)) {
      setClosedSubCategories(closedSubCategories.filter((id) => id !== subCategoryId));
    } else {
      setClosedSubCategories([...closedSubCategories, subCategoryId]);
    }
  };
  const selectedProductsArray = useMemo(() => Object.keys(selectedProducts), [selectedProducts]);
  const showCategory = !activeCategoryId;
  let showsMultiple = false;
  if (activeCategoryId) {
    const catShown = {};
    categoryProducts.forEach(
      ({ sub_category_id }) => {
        catShown[sub_category_id || '-1'] = true;
      },
    );
    console.log('So what is the catShownKeys', Object.keys(catShown));
    showsMultiple = Object.keys(catShown).length > 1 || !deepEqual(Object.keys(catShown), ['-1']);
  }
  const hasNoCategoryChildren = categoryProducts.some(({ sub_category_id }) => !sub_category_id);
  const allHidden = closedSubCategories.length === subCategories.length + (hasNoCategoryChildren ? 1 : 0);
  const onShowAll = (e) => {
    if (e) {
      e.preventDefault();
    }
    setClosedSubCategories([]);
  };
  const onHideAll = (e) => {
    if (e) {
      e.preventDefault();
    }
    setClosedSubCategories([
      ...(hasNoCategoryChildren ? [-1] : []),
      ...subCategories.map(({ id }) => id)
    ]);
  };
  return children({
    props: {
      allCheckbox: {
        checked: allChecked,
        indeterminate: someChecked && !allChecked,
        onChange: onToggleAll,
      },
      productsContent: {
        onToggleSubCategoryOpen,
        closedSubCategories,
        onToggleSubCategory,
        getSubCategoryCheckboxState,
        onToggleProduct,
        selectedProducts,
        activeCategoryId,
        hasNoCategoryChildren,
        showsMultiple,
        selectedProductsArray,
      },
    },
    showCategory,
    selectedProducts: selectedProductsArray,
    showsMultiple,
    onShowAll,
    onHideAll,
    allHidden,
  });
};

export default ProductsController;
