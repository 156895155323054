import { Button, Col } from "antd";

export const MenuSettingsHeader = ({ children }) => {
  return (
    <div className="MenuSettingsHeader flex justify-between items-center mb-6 575:flex-row flex-col gap-4 575:gap-0">
      {children}
    </div>
  );
};

export const MenuSettingsButtonsWrapper = ({ children }) => {
  return (
    <div className="MenuSettingsButtonsWrapper flex items-center gap-4 justify-center 575:justify-start w-full 575:w-auto">
      {children}
    </div>
  );
};

export const MenuSettingsTabs = ({ children }) => {
  return <div className="MenuSettingsTabs flex gap-1 mb-6">{children}</div>;
};

export const MenuSettingsTab = ({ children, active, ...props }) => {
  return (
    <Button
      className={`MenuSettingsTab flex justify-center items-center py-2 px-4 border-0 rounded-full text-sm font-medium leading-6 h-8 cursor-pointer ${
        active
          ? "bg-yumyGreen text-white-100 hover:!bg-yumyGreen hover:!text-white-100"
          : "bg-grayScale900-4 text-yumyBlack hover:!bg-grayScale900-4"
      }`}
      {...props}
    >
      {children}
    </Button>
  );
};

export const MenuSettingsButtonWrap = ({ children }) => {
  return <div className="MenuSettingsButtonWrap">{children}</div>;
};

export const MenuSettingsTitle = ({ children }) => {
  return (
    <h1 className="MenuSettingsTitle text-2xl font-extrabold leading-140">
      {children}
    </h1>
  );
};

export const MenuSettingsSubTitle = ({ children }) => {
  return (
    <h2 className="MenuSettingsSubTitle text-sm font-semibold leading-180 mb-4">
      {children}
    </h2>
  );
};

export const MenuSettingsInfoChoices = ({ children }) => {
  return (
    <div className="MenuSettingsInfoChoices grid grid-cols-1 575:grid-cols-2 mb-4 575:mb-0 gap-6">
      {children}
    </div>
  );
};

export const MenuSettingsTimeCol = ({ children }) => {
  return (
    <Col className="MenuSettingsTimeCol border-b border-grayScale900-8 pb-4">
      {children}
    </Col>
  );
};

export const MenuSettingsTimeSlot = ({ children }) => {
  return (
    <button className="MenuSettingsTimeSlot flex items-center gap-2 rounded-full pr-2 pl-4 bg-grayScale900-4 cursor-pointer border-0 text-xs font-semibold leading-180 hover:bg-grayScale900-8">
      {children}
    </button>
  );
};
