
import createInput from './createInput';

const TabToggle = ({ options, value, onChange }) => {
  console.log('value', value);
  console.log('options', options);  
  return (
    <div>
      {options.map((option) => (
        <button
          key={option.value}
          onClick={(evt) => { evt.preventDefault(); onChange(option.value)}}
          className={`${
            option.value === value
              ? "bg-yumyGreen600 text-white"
              : "bg-gray-900 text-gray-900"
          } px-4 py-2 rounded`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default createInput(TabToggle);