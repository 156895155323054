import { useLink, useLogin } from "@refinedev/core";
import useForm from "../../lib/refine/useForm";
import { Button } from "antd";
import Form from "../../lib/Form";
import { Input, Password } from "../../components/Inputs";
import { Body } from "../../components/Layout/typography";
import {
  FormContent,
  FormWrapper,
  SwitchText,
} from "../../components/Layout/forms";

export default function Login() {
  const { mutate: onLogin } = useLogin();
  const { formProps } = useForm({
    resource: "users",
    action: "create",
    successNotification: "User has been created",
    onMutationSuccess: (_, { email, password }) => {
      onLogin({ email, password });
    },
  });
  const Link = useLink();
  return (
    <FormWrapper>
      <FormContent>
        <div>
          <h1>Sign up for Yumy</h1>
          <Body md gray48>
            Start your digital journey with Yumy for free{" "}
          </Body>
        </div>
        <Form
          {...formProps}
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
          }}
        >
          <Input label="First name" name="first_name" />
          <Input label="Last name" name="last_name" />
          <Input label="Email" name="email" autoComplete="off" />
          <Password.PasswordWithRules
            label="Password"
            name="password"
            autoComplete="new-password"
          />
          <Body md gray48>
            By creating an account, you agree to our{" "}
            <Link to="terms">Terms</Link> and have read and acknowledge the{" "}
            <Link to="privacy">Global Privacy Statement</Link>.
          </Body>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="large"
          >
            Sign up
          </Button>
        </Form>
        <SwitchText>
          or <Link to="/login">log in</Link>
        </SwitchText>
      </FormContent>
    </FormWrapper>
  );
}
