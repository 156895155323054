import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useList, useOne } from "../../lib/refine";
import { useModal } from "@refinedev/antd";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const emptyObject = {};
const emptyArray = [];
const PageController = ({ children }) => {
  const { restaurantId } = useParams();
  const { data: restaurantData, isLoading: isLoadingRestaurant } = useOne({
    resource: "restaurants",
    id: restaurantId,
    suspenseKey: "PRODUCTS_RESTAURANT",
  });
  const { data: sessionPurchases, isLoading: isLoadingSessionPurchases } =
    useList({
      resource: "restaurants/session-purchases",
      id: restaurantId,
      queryOptions: {
        refetchInterval: 5000,
        refetchIntervalInBackground: true,
      },
      suspenseKey: "SESSION_PURCHASES",
      pagination: false,
      hasPagination: false,
      filters: [{ field: "today", operator: "eq", value: true }],
      sorters: [
        {
          field: "order_id",
          order: "asc",
        },
      ],
    });
  const fullyLoaded = !isLoadingRestaurant && !isLoadingSessionPurchases;
  const fullScreenHandle = useFullScreenHandle();
  const modalInFullscreenRef = useRef();
  const { modalProps, show: openModal, close: closeModal } = useModal();
  return (
    <FullScreen
      handle={fullScreenHandle}
      fullScreenActive={fullScreenHandle.active}
    >
      <div
        className={`${
          fullScreenHandle.active ? "bg-white-100 px-4 pb-4 pt-0" : ""
        } h-full`}
      >
        <div id="modalInFullScreen" ref={modalInFullscreenRef}></div>
        {children({
          restaurant: restaurantData?.data || emptyObject,
          props: {
            settingsModal: {
              ...modalProps,
              maskClosable: false,
              closeIcon: false,
              footer: null,
              getContainer: modalInFullscreenRef.current,
            },
            settings: {
              closeModal,
            },
            settingsButton: {
              onClick: openModal,
            },
            filterOrderState: {
              sessionPurchases: sessionPurchases?.data || emptyArray,
            },
          },
          fullyLoaded,
          onToggleFullScreen: () => {
            if (fullScreenHandle.active) {
              console.log("Is active, exiting");
              fullScreenHandle.exit();
            } else {
              console.log("Is not active, entering");
              fullScreenHandle.enter();
            }
          },
          fullScreenActive: fullScreenHandle.active,
        })}
      </div>
    </FullScreen>
  );
};

export default PageController;
