import FormController from "./Controllers/FormController";
import ModalController from "./Controllers/ModalController";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import { Input, Select } from "../../../components/Inputs";
import { Button, Form } from "antd";
import Popconfirm from "../../../components/Popconfirm";
import { FormContent } from "../../../components/Layout/forms";
import {
  MenuSettingsButtonsWrapper,
  MenuSettingsHeader,
  MenuSettingsInfoChoices,
  MenuSettingsTitle,
} from "../../../components/Layout/menuSettingsCustom";

const MenuSettings = ({ closeModal }) => (
  <ModalController closeModal={closeModal}>
    {({ props, formKey }) => (
      <FormController key={formKey} {...props.formController}>
        {({ formProps }) => (
          <Form {...formProps}>
            <FormContent modal>
              <MenuSettingsHeader>
                <MenuSettingsTitle>Restaurant settings</MenuSettingsTitle>
                <ButtonSectionController {...props.buttonSection}>
                  {({ props, hasChanges }) => (
                    <MenuSettingsButtonsWrapper>
                      <Button
                        id="menuSettingsSave"
                        className="action"
                        type="primary"
                        htmlType="submit"
                        {...props.save}
                      >
                        {hasChanges ? "Save" : "Saved"}
                      </Button>
                      {hasChanges && (
                        <Popconfirm
                          title="Cancel edit"
                          description={
                            <>
                              <p>
                                Possible edits will be lost.
                                <br />
                                Are you sure you want to cancel?
                              </p>
                            </>
                          }
                          okText="Yes"
                          cancelText="No"
                          {...props.popConfirm}
                        >
                          <Button id="menuSettingsCancel" className="action">
                            Cancel
                          </Button>
                        </Popconfirm>
                      )}
                      {!hasChanges && (
                        <Button
                          id="menuSettingsClose"
                          {...props.close}
                          className="action"
                        >
                          Close
                        </Button>
                      )}
                    </MenuSettingsButtonsWrapper>
                  )}
                </ButtonSectionController>
              </MenuSettingsHeader>
              <div>
                <MenuSettingsInfoChoices>
                  <Input label="Restaurant name" name="name" />
                  <Select
                    {...props.countrySelect}
                    label="Country"
                    name="country_id"
                  />
                  <Input name="address" label="Address" />
                  <Input name="zip" label="Zip code" />
                  <Input name="city" label="City" />
                </MenuSettingsInfoChoices>
              </div>
            </FormContent>
          </Form>
        )}
      </FormController>
    )}
  </ModalController>
);

export default MenuSettings;
