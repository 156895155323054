import { useState } from 'react';
import { useGo, useInvalidate } from '@refinedev/core';
import { useForm } from '../../../../..//lib/refine';


export default function ProductFormController({
  initialValues,
  setTemporaryProduct,
  productId,
  children,
}) {
  const invalidate = useInvalidate();
  const go = useGo();
  const {
    formProps,
  } = useForm({
    resource: 'restaurants/menus/products',
    action: productId ? 'edit' : 'create',
    successNotification: productId ? 'Product updated' : 'Product created',
    id: productId,
    redirect: false,
    onMutationSuccess: (p) => {
      setTemporaryProduct(p.data);
      if (!productId) {
        go({ to: `../edit-product/${p.data.id}` });
      }
      invalidate({
        resource: 'restaurants/menus/products',
        invalidates: ['list'],
      });
    },
  });
  const [showingAllOptions, setShowingAllOptions] = useState(true);
  return children({
    props: {
      form: {
        ...formProps,
        initialValues,
      },
      buttonSection: {
        initialValues,
        edit: !!productId,
      },
    },
    edit: Boolean(productId),
    showingAllOptions,
    setShowingAllOptions,
  });
}

