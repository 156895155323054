import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import { Dropdown, Button, Flex, Image, Tag, Checkbox, Space } from "antd";
import { YumyHeader, colors } from "../../../components/Layout/template";
import { Overline, Body, Caption } from "../../../components/Layout/typography";
import { YumyIconPlus, YumyIconSettings } from "../../../components/YumyIcons";
import DragHandler from "../../../components/Functionality/DragHandler";
import ProductsPageController from "./Controllers/ProductsPageController";
import ProductSectionController from "./Controllers/ProductSectionController";
import ProductEditController from "./Controllers/ProductEditController";
import ProductDropdownController from "./Controllers/ProductDropdownController";
import AllProductsDropdownController from "./Controllers/AllProductsDropdownController";
import {
  DeleteOutlined,
  CopyOutlined,
  EditOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
// import SubCategoryDropdownController from './Controllers/SubCategoryDropdownController';
import ProductsContentController from "./Controllers/ProductsContentController";
import ProductsController from "./Controllers/ProductsController";
import ProductRowController from "./Controllers/ProductRowController";
import { Suspense } from "../../../lib/refine";
import {
  YumyProductCategories,
  YumyProductCategoriesButton,
  YumyProductCategoriesWrap,
  YumyProducts,
  YumyProductsCategoryHeader,
  YumyProductsColumn,
  YumyProductsColumnResponsive,
  YumyProductsMore,
  YumyProductsRow,
} from "../../../components/Layout/products";
import LoaderSkeleton from "./LoaderSkeleton";
import { useLink } from "@refinedev/core";
// import Statistics from '../../../components/Statistics';

const Products = ({ children: subRoutes }) => {
  const { menuId } = useParams();
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  console.log("Is it Products?", Boolean(ref.current));
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true, // activate rubber band effect
  });
  const Link = useLink();
  // const [searchActive, setSearchActive] = useState(); // We will use React useState hook to store the search value
  return (
    <Suspense fallback={<LoaderSkeleton />}>
      <ProductsPageController key={menuId}>
        {({ restaurant, menu, addMenu, goToSettings }) => (
          <>
            <YumyHeader>
              <div>
                <Overline>{restaurant?.name}</Overline>
                <h3>{menu?.name}</h3>
              </div>
              <Flex gap={24} align="center" justify="space-between">
                <Dropdown menu={addMenu} trigger="click">
                  <Button type="primary" className="addNew">
                    Add new <YumyIconPlus />
                  </Button>
                </Dropdown>
                <div
                  className="flex items-center gap-2 cursor-pointer hover:fill-yumyGreen transition-colors duration-300 ease-in-out"
                  onClick={goToSettings}
                >
                  <YumyIconSettings />
                </div>
              </Flex>
            </YumyHeader>
            <Flex vertical gap={40}>
              {/*}
            <Statistics 
              title="Sales"
              statisticItems={[
                { value: '-', title: "Revenue" },
                { value: '-', title: "Orders" },
                { value: '-', title: "QR Scan" },
                { value: '-', title: "No product is most sold yet" }
              ]}
            />
            */}
              <ProductSectionController>
                {({
                  categoryChoices,
                  activeCategoryName,
                  activeCategoryId,
                }) => (
                  <>
                    <YumyProductCategoriesWrap>
                      <div className="flex gap-2 items-center">
                        <Body sm>Categories</Body>
                        <div>
                          (<Link to="./categories">Edit</Link>)
                        </div>
                      </div>
                      <YumyProductCategories
                        {...events}
                        ref={ref} // add reference and events to the wrapping div
                      >
                        {categoryChoices.map(
                          ({ key, name, products, isActive, onClick }) => (
                            <YumyProductCategoriesButton
                              onClick={onClick}
                              key={key}
                              active={isActive}
                            >
                              {name} ({products})
                            </YumyProductCategoriesButton>
                          )
                        )}
                      </YumyProductCategories>
                    </YumyProductCategoriesWrap>
                    <ProductsController
                      activeCategoryId={activeCategoryId}
                      key={activeCategoryId}
                    >
                      {({
                        showCategory,
                        selectedProducts,
                        onShowAll,
                        onHideAll,
                        allHidden,
                        props,
                        showsMultiple,
                      }) => (
                        <YumyProducts>
                          <Body sm>
                            {activeCategoryName === "All"
                              ? "All Products"
                              : activeCategoryName}
                          </Body>
                          <Flex vertical>
                            <YumyProductsRow headers>
                              <YumyProductsColumn checkbox>
                                <Checkbox {...props.allCheckbox} />
                              </YumyProductsColumn>
                              {selectedProducts.length > 0 && (
                                <YumyProductsColumn selected>
                                  <Caption>
                                    Selected {selectedProducts.length}
                                  </Caption>
                                </YumyProductsColumn>
                              )}
                              {selectedProducts.length === 0 && (
                                <>
                                  {props.dragHandle && <div />}
                                  <YumyProductsColumn product>
                                    <Caption>Product</Caption>
                                  </YumyProductsColumn>
                                  {showCategory && (
                                    <YumyProductsColumn category>
                                      <Caption>Category</Caption>
                                    </YumyProductsColumn>
                                  )}
                                  <YumyProductsColumn created>
                                    <Caption>Created</Caption>
                                  </YumyProductsColumn>
                                  <YumyProductsColumn price>
                                    <Caption>Price</Caption>
                                  </YumyProductsColumn>
                                  <YumyProductsColumn status>
                                    <Caption>Status</Caption>
                                  </YumyProductsColumn>
                                </>
                              )}
                              {selectedProducts.length > 0 && (
                                <YumyProductsColumn more className="header">
                                  <AllProductsDropdownController
                                    dropdownLabel={`${selectedProducts.length} selected`}
                                    moveLabel="Move to another category"
                                    deleteLabel={`Delete all ${selectedProducts.length} selected`}
                                    noSubCategoryLabel="Main (no subcategory)"
                                    selectedProducts={selectedProducts}
                                    deleteNotification={`${selectedProducts.length} products have been deleted`}
                                    moveNotification={`${selectedProducts.length} products have been moved`}
                                  >
                                    {({ dropdownProps }) => (
                                      <Dropdown {...dropdownProps}>
                                        <div className="select-none">
                                          <YumyProductsMore>
                                            More
                                          </YumyProductsMore>
                                        </div>
                                      </Dropdown>
                                    )}
                                  </AllProductsDropdownController>
                                </YumyProductsColumn>
                              )}
                              {selectedProducts.length === 0 &&
                                showsMultiple && (
                                  <YumyProductsColumn more className="header">
                                    {allHidden ? (
                                      <div
                                        className="select-none"
                                        onClick={onShowAll}
                                      >
                                        <YumyProductsMore>
                                          Show all
                                        </YumyProductsMore>
                                      </div>
                                    ) : (
                                      <div
                                        className="select-none"
                                        onClick={onHideAll}
                                      >
                                        <YumyProductsMore>
                                          Hide all
                                        </YumyProductsMore>
                                      </div>
                                    )}
                                  </YumyProductsColumn>
                                )}
                            </YumyProductsRow>
                            <ProductsContentController
                              {...props.productsContent}
                            >
                              {({ key, subCategory, props }) => (
                                <div key={key} className="flex flex-col">
                                  {props.categoryHeader && (
                                    <YumyProductsCategoryHeader
                                      {...props.categoryHeader.container}
                                      className={`${
                                        props.categoryHeader.checkbox.checked
                                          ? " bg-yumyGreens-100"
                                          : " "
                                      }`}
                                    >
                                      <YumyProductsColumn checkbox>
                                        <span
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Checkbox
                                            {...props.categoryHeader.checkbox}
                                          />
                                        </span>
                                      </YumyProductsColumn>
                                      <YumyProductsColumn>
                                        {subCategory
                                          ? subCategory.name
                                          : "Main (no subcategory)"}
                                      </YumyProductsColumn>
                                    </YumyProductsCategoryHeader>
                                  )}
                                  <ProductRowController
                                    {...props.productRow}
                                    orderChangedNotification="Order changed"
                                  >
                                    {({ key, ordering, product, props }) => (
                                      <YumyProductsRow
                                        key={key}
                                        className={`transition-colors duration-200 ease-in-out ${
                                          props.checkbox.checked
                                            ? " bg-yumyGreens-50"
                                            : "hover:bg-grayScale900-4"
                                        }`}
                                      >
                                        <YumyProductsColumn checkbox>
                                          <Checkbox {...props.checkbox} />
                                          {props.dragHandle && (
                                            <DragHandler
                                              disabledTooltip={
                                                ordering
                                                  ? "Currently ordering something"
                                                  : "Cannot drag when products are selected"
                                              }
                                              {...props.dragHandle}
                                            />
                                          )}
                                        </YumyProductsColumn>
                                        <YumyProductsColumn
                                          product
                                          className="product-image"
                                        >
                                          <Image
                                            src={
                                              product.image
                                                ? product.image.url
                                                : "https://place-hold.it/30x50/118376/ffffff&text=img"
                                            }
                                            preview={false}
                                          />
                                          <ProductEditController
                                            product={product}
                                          >
                                            {({ onClick }) => (
                                              <p
                                                onClick={onClick}
                                                className="cursor-pointer py-3 transition-colors duration-300 hover:text-yumyGreen"
                                              >
                                                {product.name}
                                              </p>
                                            )}
                                          </ProductEditController>
                                        </YumyProductsColumn>
                                        {showCategory && (
                                          <YumyProductsColumn category>
                                            {product.category}
                                          </YumyProductsColumn>
                                        )}
                                        <YumyProductsColumn created>
                                          {product.created}
                                        </YumyProductsColumn>
                                        <YumyProductsColumn price>
                                          {product.formattedPrice}
                                        </YumyProductsColumn>
                                        <YumyProductsColumn status>
                                          <Tag
                                            className={
                                              product.active
                                                ? "active"
                                                : "draft"
                                            }
                                          >
                                            {product.active
                                              ? "Active"
                                              : "Draft"}
                                          </Tag>
                                        </YumyProductsColumn>
                                        <YumyProductsColumn more>
                                          <YumyProductsColumnResponsive>
                                            <Tag
                                              className={
                                                product.active
                                                  ? "active"
                                                  : "draft"
                                              }
                                            >
                                              {product.active
                                                ? "Active"
                                                : "Draft"}
                                            </Tag>
                                          </YumyProductsColumnResponsive>
                                          <YumyProductsColumnResponsive>
                                            <Caption>
                                              {showCategory && product.category}
                                            </Caption>
                                          </YumyProductsColumnResponsive>
                                          <ProductDropdownController
                                            product={product}
                                            duplicateLabel={
                                              <Space>
                                                <CopyOutlined />
                                                Duplicate product
                                              </Space>
                                            }
                                            disableLabel={
                                              <Space>
                                                <EyeInvisibleFilled
                                                  style={{
                                                    color:
                                                      colors.grayScale900_56,
                                                  }}
                                                />
                                                Disable
                                              </Space>
                                            }
                                            enableLabel={
                                              <Space>
                                                <EyeFilled
                                                  style={{
                                                    color: colors.yumyGreen,
                                                  }}
                                                />
                                                Enable
                                              </Space>
                                            }
                                            deleteLabel={
                                              <Space>
                                                <DeleteOutlined />
                                                Delete
                                              </Space>
                                            }
                                            disableNotification="Product has been disabled"
                                            enableNotification="Product has been enabled"
                                            deleteNotification="Product has been deleted"
                                            duplicateNotification="Product has been duplicated"
                                            anySelected={
                                              selectedProducts.length > 0
                                            }
                                            disabledTooltip="You can't access the product menu, when you have selected products"
                                            editLabel={
                                              <Space>
                                                <EditOutlined />
                                                Edit product
                                              </Space>
                                            }
                                          >
                                            {({ dropdownProps }) => (
                                              <Dropdown {...dropdownProps}>
                                                <div className="select-none">
                                                  <YumyProductsMore
                                                    disabled={
                                                      dropdownProps.disabled
                                                    }
                                                  >
                                                    More
                                                  </YumyProductsMore>
                                                </div>
                                              </Dropdown>
                                            )}
                                          </ProductDropdownController>
                                        </YumyProductsColumn>
                                      </YumyProductsRow>
                                    )}
                                  </ProductRowController>
                                </div>
                              )}
                            </ProductsContentController>
                          </Flex>
                        </YumyProducts>
                      )}
                    </ProductsController>
                  </>
                )}
              </ProductSectionController>
            </Flex>
            {subRoutes}
          </>
        )}
      </ProductsPageController>
    </Suspense>
  );
};

export default Products;
