import { forwardRef } from "react";
import { Button } from "antd";
import { Caption } from "./typography";

export const YumyProductCategoriesWrap = ({ children }) => {
  return <div className="flex flex-col w-full">{children}</div>;
};

export const YumyProductCategories = forwardRef(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-nowrap items-center gap-2 w-full py-4 overflow-x-auto scrollbar-hide"
    >
      {children}
    </div>
  );
});

export const YumyProductCategoriesButton = ({ children, active, ...props }) => {
  return (
    <Button
      {...props}
      className={`border-0 font-semibold transition-all duration-300 ease-in-out cursor-pointer ${
        active
          ? `bg-yumyGreen text-white-100 hover:!bg-yumyGreenHover hover:!text-white-100`
          : `bg-grayScale900-4 text-yumyBlack hover:!bg-yumyGreens-100 hover:!text-yumyBlack`
      }`}
    >
      {children}
    </Button>
  );
};

export const YumyProducts = ({ children }) => {
  return <div className="flex flex-col flex-wrap gap-6 w-full">{children}</div>;
};

export const YumyProductsRow = ({ children, headers, className }) => {
  return (
    <div
      className={`items-center gap-2 w-full py-4 border-b border-grayScale900-8 ${
        headers ? "flex" : "md:flex grid grid-cols-[30px,1fr]"
      }
      ${className}`}
    >
      {children}
    </div>
  );
};

export const YumyProductsCategoryHeader = ({
  children,
  className,
  active,
  ...props
}) => {
  return (
    <YumyProductsRow
      {...props}
      className={`cursor-pointer transition-colors duration-300 ease ${
        active ? "bg-grayScale900-4" : ""
      }
      ${className}
      `}
    >
      {children}
    </YumyProductsRow>
  );
};

export const YumyProductsColumn = ({
  children,
  checkbox,
  product,
  category,
  created,
  price,
  status,
  selected,
  more,
  className,
}) => {
  return (
    <div
      className={`flex items-center gap-2 w-full ${className} ${
        checkbox
          ? "shrink-0 basis-[30px] md:basis-[72px] justify-center flex-col"
          : product
          ? "basis-[calc(100%-72px-39px)] md:basis-[40%] lg:basis-[25%] shrink-0"
          : category
          ? "hidden lg:flex basis-[15%] shrink-0"
          : created
          ? "hidden lg:flex basis-[15%] shrink-0"
          : price
          ? "hidden md:flex basis-[15%] shrink-0"
          : status
          ? "basis-[100%] hidden md:flex"
          : selected
          ? "basis-[100%]"
          : more
          ? "basis-[72px] text-right shrink-0 justify-end pr-4 [&:not(.header) col-span-2 pl-9]"
          : "basis-[100%]"
      }`}
    >
      {children}
    </div>
  );
};

export const YumyProductsColumnResponsive = ({ children }) => {
  return <div className="block md:hidden">{children}</div>;
};

export const YumyProductsMore = ({ children, disabled, ...props }) => {
  return (
    <Caption
      extraClass={`transition-colors duration-300 ease ${
        disabled
          ? "text-grayScale900-30 cursor-not-allowed"
          : "cursor-pointer hover:text-yumyGreen"
      }`}
      {...props}
    >
      {children}
    </Caption>
  );
};
