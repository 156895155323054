import { Caption } from "./typography";

export const YumyModal = ({ children }) => {
  return <div className="YumyModal flex flex-col gap-6">{children}</div>;
};

export const ModalInnerWrap = ({ children }) => {
  return <div className="ModalInnerWrap flex flex-col gap-6">{children}</div>;
};

export const ModalHeader = ({ children }) => {
  return (
    <div className="ModalHeader flex flex-col 575:flex-row gap-2 575:gap-0 justify-between">
      {children}
    </div>
  );
};

export const ModalHeaderButtons = ({ children }) => {
  return (
    <div className="ModalHeaderButtons flex w-full 575:w-auto justify-start 575:justify-between gap-2">
      {children}
    </div>
  );
};

export const ModalMain = ({ children }) => {
  return (
    <div className="ModalMain grid-cols-1 700:grid-cols-ModalMain gap-6 grid">
      {children}
    </div>
  );
};

export const ModalAdditions = ({ children }) => {
  return <div className="ModalAdditions col-span-2">{children}</div>;
};

export const ModalImage = ({ children }) => {
  return (
    <div className="ModalImage flex flex-col gap-1 w-full h-full">
      {children}
    </div>
  );
};

export const ModalImageInner = ({ children }) => {
  return (
    <div className="ModalImageInner flex flex-col h-[240px] w-full">
      {children}
    </div>
  );
};

export const ModalImageContent = ({ children }) => {
  return (
    <div className="ModalImageContent flex flex-col justify-center items-center gap-2">
      {children}
    </div>
  );
};

export const ModalContent = ({ children }) => {
  return <div className="ModalContent flex flex-col gap-4">{children}</div>;
};

export const ModalDouble = ({ children }) => {
  return (
    <div className="ModalDouble flex flex-col gap-4 575:flex-row">
      {children}
    </div>
  );
};

export const ModalAdditionalOptions = ({ children }) => {
  return (
    <div className="ModalAdditionalOptions flex justify-between gap-2 575:gap-4 mb-4 flex-col 575:flex-row">
      {children}
    </div>
  );
};

export const ModalAdditionalOptionsHeading = ({ children }) => {
  return (
    <p className="ModalAdditionalOptionsHeading text-sm font-semibold leading-180 text-yumyBlack">
      {children}
    </p>
  );
};

export const ModalAdditionalSection = ({ children, mb0 }) => {
  return (
    <div
      className={`ModalAdditionalSection flex items-end gap-2 ${
        mb0 ? "mb-0" : "mb-4"
      }`}
    >
      {children}
    </div>
  );
};

export const ModalAdditionSectionBottom = ({ children }) => {
  return <div className="ModalAdditionSectionBottom pb-3">{children}</div>;
};

export const ModalAdditionSectionMore = ({ children, add, ...props }) => {
  return (
    <Caption
      extraClass={`ModalAdditionSectionMore transition-colors duration-300 ease w-8 shrink-0 text-right cursor-pointer ${
        add
          ? "text-yumyGreen hover:text-yumyGreen600 cursor-not-allowed"
          : "hover:text-yumyGreen"
      }`}
      {...props}
    >
      {children}
    </Caption>
  );
};

export const ModalOptions = ({ children }) => {
  return (
    <div className="ModalOptions flex flex-col pl-0 575:pl-[22px] w-full">
      {children}
    </div>
  );
};

export const ModalOptionsTitle = ({ children }) => {
  return (
    <Caption extraClass="ModalOptionsTitle pb-2 pl-[22px]" bold>
      {children}
    </Caption>
  );
};

export const ModalCategoryAddButton = ({ children }) => {
  return (
    <div className="ModalCategoryAddButton flex items-end w-[140px] pb-[2px]">
      {children}
    </div>
  );
};

export const ModalCategoryTextInput = ({ children, uncategorized }) => {
  return (
    <div
      className={`ModalCategoryTextInput flex items-center justify-between gap-2 w-full h-[40px] pt-[6px] px-4 pb-[5px] rounded-lg shadow-ModalCategoryTextInput transition-all ease-in-out duration-300 ${
        !uncategorized &&
        "hover:cursor-pointer hover:shadow-ModalCategoryTextInputHover"
      }`}
    >
      {children}
    </div>
  );
};
