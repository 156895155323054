import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetIdentity, useGo } from "@refinedev/core";
import { useCreate } from "../../lib/refine";
import { useModal } from "@refinedev/antd";
import { Avatar, Divider } from "antd";
import { useLogout } from "@refinedev/core";
import UserSettings from "./user-settings";
import { useHandleClickOutside } from "../../lib/hooks";
import { YumyIconAccount, YumyIconLogout, YumyIconMyPlan } from "../YumyIcons";
import Modal from "../../lib/Modal";

// Update ButtonStyled to conditionally display
const DivStyled = ({ visible, children }) => {
  return (
    <div
      className={`DivStyled flex flex-col absolute bottom-[45px] left-[10px] md:left-[22.5px] z-[1] transition-all duration-300 ease-linear bg-white-100 p-2 rounded-xl w-[180px] md:w-[200px] shadow-UserDiv ${
        visible
          ? "opacity-100 visible translate-y-0 delay-0"
          : " opacity-0 invisible translate-y-5"
      }`}
    >
      {children}
    </div>
  );
};

const Name = ({ children }) => {
  return (
    <div className="Name text-xs font-semibold text-grayScale900-56 leading-180 flex items-center self-stretch py-[6px] px-2">
      {children}
    </div>
  );
};

const UserButton = ({ children, ...props }) => {
  return (
    <button
      className="UserButton cursor-pointer border-0 w-full !flex !py-1 !px-2 !items-center !justify-start !gap-1 !rounded-lg text-yumyBlack text-xs leading-180 bg-white-100 hover:bg-yumyLight hover:text-yumyBlack"
      {...props}
    >
      {children}
    </button>
  );
};

const UserMenu = ({
  visible,
  onClose,
  name,
  onClickAccount,
  hideAccountAndPlan,
}) => {
  const { mutate: onLogout } = useLogout();
  const [isCreatingEnteringPlan, setIsEnteringPlan] = useState(false);
  const { restaurantId } = useParams();
  const { mutate } = useCreate();
  const { data: identity } = useGetIdentity();
  const go = useGo();
  return (
    <DivStyled visible={visible}>
      <Name>{name}</Name>
      {!hideAccountAndPlan && (
        <>
          {identity?.activePlan === "PREMIUM" && (
            <UserButton
              onClick={(e) => {
                const currentUrl = window.location.href;
                setIsEnteringPlan(true);
                mutate({
                  resource: "users/plans/portal-sessions",
                  id: identity?.active_plan_id,
                  values: {
                    return_url: currentUrl,
                  },
                  meta: {
                    planId: identity?.active_plan_id,
                  },
                  onMutationSuccess: (data) => {
                    window.location.href = data.data.url;
                  },
                });
                onClose();
              }}
            >
              <YumyIconMyPlan />
              My Plan
            </UserButton>
          )}
          <UserButton
            onClick={(e) => {
              // e.stopPropagation(); // Prevent click from propagating to window
              onClickAccount();
              onClose();
            }}
          >
            <YumyIconAccount />
            Account
          </UserButton>
        </>
      )}
      <Divider style={{ margin: "4px 0" }} />
      <UserButton
        onClick={(e) => {
          e.stopPropagation(); // Prevent click from propagating to window
          onLogout();
        }}
      >
        <YumyIconLogout />
        Logout
      </UserButton>
    </DivStyled>
  );
};

const UserWrap = ({ children }) => {
  return (
    <div className="UserWrap relative flex justify-start py-0 px-2">
      {children}
    </div>
  );
};

const AvatarStyled = ({ isOpen, children, ...props }) => {
  return (
    <Avatar
      className={`AvatarStyled text-yumyBlack !text-sm font-medium align-middle cursor-pointer transition-all duration-300 ease-in-out select-none ${
        isOpen ? "bg-white-56" : "bg-white-100 hover:bg-white-56"
      }`}
      {...props}
    >
      {children}
    </Avatar>
  );
};

const getUserInitials = (user) => {
  if (!user) {
    return null;
  }
  const { first_name, last_name } = user;
  const firstInitial = first_name.charAt(0);
  const lastInitial = last_name.charAt(0);

  const initials = `${firstInitial}${lastInitial}`;

  return initials.toUpperCase();
};
const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const { first_name, last_name } = user;
  return `${first_name} ${last_name}`;
};

const User = ({ hideAccountAndPlan }) => {
  const [showLogout, setShowLogout] = useState(false);
  const wrapperRef = useHandleClickOutside(() =>
    showLogout ? setShowLogout(false) : undefined
  );
  const { data: identity } = useGetIdentity();
  const { modalProps, show, close } = useModal();
  return (
    <UserWrap ref={wrapperRef}>
      <AvatarStyled
        size={40}
        isOpen={showLogout}
        onClick={(e) => {
          e.stopPropagation(); // Prevent click from propagating to window
          setShowLogout(!showLogout);
        }}
      >
        {getUserInitials(identity)}
      </AvatarStyled>
      <UserMenu
        visible={showLogout}
        onClickAccount={show}
        onClose={() => setShowLogout(false)}
        name={getUserName(identity)}
        hideAccountAndPlan={hideAccountAndPlan}
      />
      <Modal
        {...modalProps}
        maskClosable={false}
        closeIcon={false}
        footer={null}
        width={940}
      >
        <UserSettings closeModal={close} />
      </Modal>
    </UserWrap>
  );
};

export default User;
