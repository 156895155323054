export const Body = ({
  gray48,
  gray40,
  extraClass,
  xl,
  lg,
  md,
  sm,
  children,
}) => (
  <p
    className={`leading-180 font-normal m-0 ${
      gray48
        ? "text-grayScale900-48"
        : gray40
        ? "text-grayScale900-40"
        : "text-grayScale900-56"
    } ${
      xl
        ? "text-xl"
        : lg
        ? "text-lg"
        : md
        ? "text-base"
        : sm
        ? "text-sm"
        : "text-xs"
    } ${extraClass ? extraClass : ""}`}
  >
    {children}
  </p>
);

export const Overline = ({ gray, lg, textCenter, children }) => (
  <p
    className={`leading-170 font-bold uppercase m-0 ${
      gray ? "text-white-40" : "text-yumyGreen"
    } ${lg ? "text-sm" : "text-xs"} ${textCenter ? "text-center" : ""}`}
  >
    {children}
  </p>
);

export const Caption = ({ children, extraClass }) => (
  <p
    className={`${
      extraClass ? extraClass : ""
    } text-xs font-semibold leading-180 m-0 text-grayScale900-56`}
  >
    {children}
  </p>
);
