export const FormWrapper = ({ children }) => {
  return (
    <div className="FormWrapper flex justify-center flex-1 border-l-8 border-yumyGreen px-4 575:px-0">
      {children}
    </div>
  );
};

export const FormContent = ({ children, onboarding, modal }) => {
  return (
    <div
      className={`FormContent ${onboarding ? "onboarding" : ""} ${
        modal ? "modal" : ""
      }`}
    >
      {children}
    </div>
  );
};

export const InputWrapper = ({ children }) => {
  return (
    <div className="InputWrapper flex flex-col gap-1 w-full">{children}</div>
  );
};

export const InputWrapperRow = ({ children }) => {
  return (
    <div className="InputWrapperRow flex flex-row gap-1 items-center">
      {children}
    </div>
  );
};

export const Label = ({ children, noLabelHeight }) => {
  return (
    <label
      className={`Label ${
        noLabelHeight ? "h-auto" : "h-8"
      } flex justify-between text-xs font-semibold shrink-0 leading-180 items-center text-grayScale900-56`}
    >
      {children}
    </label>
  );
};

export const SwitchText = ({ children }) => {
  return (
    <div className="SwitchText flex justify-center gap-2 text-base font-medium text-yumyBlack">
      {children}
    </div>
  );
};
