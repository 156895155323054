export const colors = {
  yumyGreen: "#118376",
  yumyGreenHover: "#298f80",
  yumyGreen10: "#E7FFFC",
  yumyGreen600: "#095C53",
  yumyWhite: "#FFFFFF",
  yumyLight: "#F5F5F5",
  yumyBlack: "#121127",
  yumyRed: "#EF4444",

  gray: "#F5F5F5",
  grayScale900_72: "rgba(18, 17, 39, 0.72)",
  grayScale900_56: "rgba(18, 17, 39, 0.56)",
  grayScale900_48: "rgba(18, 17, 39, 0.48)",
  grayScale900_40: "rgba(18, 17, 39, 0.4)",
  grayScale900_32: "rgba(18, 17, 39, 0.32)",
  grayScale900_30: "rgba(18, 17, 39, 0.3)",
  grayScale900_12: "rgba(18, 17, 39, 0.12)",
  grayScale900_8: "rgba(18, 17, 39, 0.08)",
  grayScale900_4: "rgba(18, 17, 39, 0.04)",
  grayScale900_2: "rgba(18, 17, 39, 0.02)",

  white56: "rgba(255, 255, 255, 0.56)",
  white40: "rgba(255, 255, 255, 0.4)",
  white24: "rgba(255, 255, 255, 0.24)",
  white12: "rgba(255, 255, 255, 0.12)",
  white8: "rgba(255, 255, 255, 0.08)",
  white4: "rgba(255, 255, 255, 0.04)",

  green10: "rgba(17, 131, 118, 0.10)",

  yumyGreens100: "#E7F3F1",
  yumyGreens200: "#F3FDFC",
};

/*
------------------------------------------------------
  Global
------------------------------------------------------
*/
export const Page = ({ children, modal, ...props }) => {
  return (
    <div
      className={`Page flex flex-col ${
        modal ? "" : "min-h-dvh justify-between"
      }`}
      {...props}
    >
      {children}
    </div>
  );
};

/*
------------------------------------------------------
  Layout
------------------------------------------------------
*/
export const YumyLayout = ({ children }) => {
  return (
    <div className="YumyLayout grid grid-cols-1 md:grid-cols-template h-dvh w-full relative">
      {children}
    </div>
  );
};

export const YumySider = ({ children, menuOpen }) => {
  return (
    <div
      className={`YumySider flex flex-col justify-between gap-[10px] pb-[50px] text-white-100 bg-yumyBlack ${
        menuOpen ? "menuOpen" : ""
      }`}
    >
      {children}
    </div>
  );
};

export const YumySiderDropdown = ({ children }) => {
  return (
    <div className="YumySiderDropdown flex flex-col gap-[10px] px-4">
      {children}
    </div>
  );
};
export const YumySiderToggle = ({ children, menuOpen, ...props }) => {
  return (
    <div className={`YumySiderToggle ${menuOpen ? "menuOpen" : ""}`} {...props}>
      {children}
    </div>
  );
};
export const YumyOverlay = ({ menuOpen, ...props }) => {
  return (
    <div
      className={`YumyOverlay fixed top-0 z-[798] left-0 right-0 bottom-0 bg-yumyBlack transition-all duration-200 ease-linear ${
        menuOpen ? "opacity-50 visible" : "opacity-0 invisible"
      }`}
      {...props}
    />
  );
};

export const YumySiderOnboarding = ({ children }) => {
  return (
    <div className="YumySiderOnboarding flex flex-col gap-6 px-4">
      {children}
    </div>
  );
};

export const YumySiderOnboardingText = ({ children }) => {
  return <p className="YumySiderOnboardingText text-xs m-0">{children}</p>;
};

export const YumyHeader = ({ children }) => {
  return (
    <header className="YumyHeader flex justify-between flex-col gap-4 575:gap-0 575:flex-row 575:items-center mb-6">
      {children}
    </header>
  );
};
/*
------------------------------------------------------
  Content
------------------------------------------------------
*/
export const Logo = ({ children }) => {
  return <div className="Logo flex mt-[30px] mb-[58px] ml-4">{children}</div>;
};

export const OnboardingContent = ({ children }) => {
  return (
    <div className="OnboardingContent flex flex-col items-center justify-center gap-4 transition-all duration-300 ease-in-out mt-0 md:mt-[30px] 992:mt-20">
      {children}
    </div>
  );
};
