export const Dropdown = ({ children, isOpen }) => {
  return (
    <div
      className={`SiderDropdown flex flex-col overflow-hidden transition-all ease-in-out duration-300 m-1 rounded-lg gap-4 bg-white-4 transform ${
        isOpen
          ? "py-1 pt-1 pb-4 max-h-full opacity-100 translate-y-0"
          : "py-0 px-1 max-h-0 opacity-0 -translate-y-4"
      }`}
    >
      {children}
    </div>
  );
};
export const DropdownElipsis = ({ children }) => {
  <div className="DropdownElipsis whitespace-nowrap overflow-hidden text-ellipsis text-left w-[140px]">
    {children}
  </div>;
};

export const DropdownButtonArrow = ({ isOpen, children }) => {
  return (
    <div
      className={`DropdownButtonArrow flex items-center justify-center ${
        isOpen ? "isOpen" : ""
      }`}
    >
      {children}
    </div>
  );
};

export const DropdownButton = ({ children, isOpen, ...props }) => {
  return (
    <div
      className={`DropdownButton p-2 m-1 flex justify-between cursor-pointer rounded-lg transition-colors ease-in-out duration-200 select-none hover:bg-green10 ${
        isOpen ? "bg-green10" : ""
      }`}
      {...props}
    >
      {children}
    </div>
  );
};

export const DropdownButtonInner = ({ children }) => {
  return (
    <div className="DropdownButtonInner flex gap-2 items-center">
      {children}
    </div>
  );
};

export const DropdownButtonText = ({ children }) => {
  return (
    <div className="DropdownButtonText whitespace-nowrap overflow-hidden text-ellipsis w-[120px]">
      {children}
    </div>
  );
};

export const Separator = () => {
  return <div className="Separator bg-white-8 h-[1px] my-6" />;
};
