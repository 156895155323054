import { Popconfirm as AntdPopconfirm } from 'antd';
import { YumyIconExclamationCircle } from './YumyIcons';


const Popconfirm = ({ icon, ...props }) => {
  return (
    <AntdPopconfirm
      {...props}
      icon={icon || <YumyIconExclamationCircle />}
    />
  );
};

export default Popconfirm;
