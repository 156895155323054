import { useState } from "react";
import { Divider, Flex, Switch } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddSectionButton from "./AddSectionButton";
import Section from "./Section";
import {
  ModalAdditionalOptions,
  ModalAdditionalOptionsHeading,
} from "../../../../components/Layout/modal";

const Sections = ({
  fields,
  add,
  remove,
  move,
  showingAllOptions,
  setShowingAllOptions,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };
  const [showingSpecificSection, setShowingSpecificSetion] = useState(null);
  return (
    <div>
      <Divider />
      <ModalAdditionalOptions>
        <ModalAdditionalOptionsHeading>
          Additional Options
        </ModalAdditionalOptionsHeading>
        {fields.length > 0 && (
          <Switch
            checkedChildren="Collapse options"
            unCheckedChildren="Expand options"
            label="Show Section-Options"
            onChange={setShowingAllOptions}
            value={showingAllOptions}
          />
        )}
      </ModalAdditionalOptions>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {({ placeholder, innerRef, droppableProps }) => (
            <Flex vertical {...droppableProps} ref={innerRef}>
              {fields.map(({ key, name }) => (
                <Draggable key={key} draggableId={`${key}`} index={name}>
                  {({ draggableProps, dragHandleProps, innerRef }) => (
                    <Section
                      key={key}
                      name={name}
                      onRemove={() => remove(name)}
                      draggableProps={draggableProps}
                      dragHandleProps={dragHandleProps}
                      innerRef={innerRef}
                      showingAllOptions={showingAllOptions}
                      showingOptions={showingSpecificSection === key}
                      setShowing={() => setShowingSpecificSetion(key)}
                      unsetShowing={() => setShowingSpecificSetion(null)}
                      showingAnyOption={showingSpecificSection !== null}
                    />
                  )}
                </Draggable>
              ))}
              {placeholder}
            </Flex>
          )}
        </Droppable>
        <AddSectionButton
          onClick={() =>
            add({
              name: "",
              type: "SINGLE",
              required_state: "OPTIONAL",
              options: [{ name: "", price: 0 }],
            })
          }
        />
      </DragDropContext>
    </div>
  );
};

export default Sections;
