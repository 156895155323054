import FormController from "./Controllers/FormController";
import ModalController from "./Controllers/ModalController";
import ButtonSectionController from "./Controllers/ButtonSectionController";
import { Input } from "../../../../components/Inputs";
import { Form, Button, Flex } from "antd";
import Popconfirm from "../../../../components/Popconfirm";
import { FormContent } from "../../../../components/Layout/forms";
import {
  MenuSettingsButtonsWrapper,
  MenuSettingsHeader,
  MenuSettingsButtonWrap,
  MenuSettingsInfoChoices,
  MenuSettingsTitle,
} from "../../menuSettingsCustom";
const Profile = ({ closeModal, onGotoPassword, onGotoEmail }) => (
  <ModalController closeModal={closeModal}>
    {({ props, formKey }) => (
      <FormController key={formKey} {...props.formController}>
        {({ formProps }) => (
          <Form {...formProps}>
            <FormContent modal>
              <MenuSettingsHeader>
                <MenuSettingsTitle>Account</MenuSettingsTitle>
                <ButtonSectionController {...props.buttonSection}>
                  {({ props, hasChanges }) => (
                    <MenuSettingsButtonsWrapper>
                      <Button
                        id="menuSettingsSave"
                        className="action"
                        type="primary"
                        htmlType="submit"
                        {...props.save}
                      >
                        {hasChanges ? "Save" : "Saved"}
                      </Button>
                      {hasChanges && (
                        <Popconfirm
                          title="Cancel edit"
                          description={
                            <>
                              <p>
                                Possible edits will be lost.
                                <br />
                                Are you sure you want to cancel?
                              </p>
                            </>
                          }
                          okText="Yes"
                          cancelText="No"
                          {...props.popConfirm}
                        >
                          <Button id="menuSettingsCancel" className="action">
                            Cancel
                          </Button>
                        </Popconfirm>
                      )}
                      {!hasChanges && (
                        <Button
                          id="menuSettingsClose"
                          {...props.close}
                          className="action"
                        >
                          Close
                        </Button>
                      )}
                    </MenuSettingsButtonsWrapper>
                  )}
                </ButtonSectionController>
              </MenuSettingsHeader>
              <div>
                <Flex vertical gap={8}>
                  <MenuSettingsInfoChoices>
                    <Input label="First name" name="first_name" />
                    <Input name="last_name" label="Last name" />
                    <Input name="email" label="Email" disabled readOnly />
                  </MenuSettingsInfoChoices>
                  <MenuSettingsButtonWrap>
                    <Button onClick={onGotoEmail}>Change email</Button>
                  </MenuSettingsButtonWrap>
                </Flex>
                <Flex vertical gap={8} style={{ marginTop: "16px" }}>
                  <b>Password</b>
                  <MenuSettingsButtonWrap>
                    <Button onClick={onGotoPassword}>Change password</Button>
                  </MenuSettingsButtonWrap>
                </Flex>
              </div>
            </FormContent>
          </Form>
        )}
      </FormController>
    )}
  </ModalController>
);

export default Profile;
