import {
  Input as AntdInput,
  Select as AntdSelect,
  Checkbox as AntdCheckbox,
  InputNumber as AntdInputNumber,
  Switch as AntdSwitch,
} from 'antd';
import createInput from './createInput';
import Password from './Password';
import TimePicker from './TimePicker';
import TabToggle from './TabToggle';

export const Input = createInput(AntdInput);

export const InputNumber = createInput(AntdInputNumber);

export const CurrencyInput = createInput(AntdInputNumber, {
  defaultProps: () => ({
    placeholder: 'Add price (required)',
    decimalSeparator: ',',
    value: undefined,
  }),
});

export {
  Password,
  createInput,
  TimePicker,
  TabToggle,
}

export const TextArea = createInput(AntdInput.TextArea);

export const Search = createInput(AntdInput.Search);

export const Group = createInput(AntdInput.Group);

export const Select = createInput(AntdSelect);

export const Option = createInput(AntdSelect.Option);

export const OptGroup = createInput(AntdSelect.OptGroup);

export const Checkbox = createInput(AntdCheckbox, { defaultProps: () => ({ noStyle: true, valuePropName: 'checked' }) });

export const Switch = createInput(AntdSwitch, { defaultProps: () => ({ noStyle: true, valuePropName: 'checked' }) });